import { Customer } from 'modules/travel/models'

export default class User {
  id: number = 0
  name: string = ''
  birthday: string = ''
  phone: string = ''
  email: string = ''
  pass: string = ''
  token: string = ''
  customer?: Customer

  constructor(user?: Partial<User>) {
    Object.assign(this, user)
  }
}
