import { Reducer } from 'redux'
import { InvoiceState, InvoiceActions } from './types'
import { Invoice } from 'modules/invoice/models'

// Type-safe initialState!
const initialState: InvoiceState = {
  currentInvoice: new Invoice()
}

const reducer: Reducer<InvoiceState> = (state = initialState, action) => {
  switch (action.type) {
    case InvoiceActions.SET_INVOICE_DATA: {
      return {
        ...state,
        currentInvoice: { ...state.currentInvoice, ...action.payload }
      }
    }
    case InvoiceActions.REMOVE_INVOICE_DATA: {
      return {
        ...state,
        currentInvoice: { ...initialState.currentInvoice }
      }
    }
    default: {
      return state
    }
  }
}

export { reducer as invoiceReducer }
