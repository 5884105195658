import React from 'react'
import { Provider } from 'react-redux'

import { store } from './src/createStore'

import { Auth } from './src/auth/components'

// eslint-disable-next-line react/display-name,react/prop-types
export const web = ({ element }) => (
  <Provider store={store}>
    <Auth>{element}</Auth>
  </Provider>
)

export const ssr = ({ element }) => <Provider store={store}>{element}</Provider>
