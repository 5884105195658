import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { Action } from 'redux'
import { ApplicationState } from 'store'
import { Invoice } from 'modules/invoice/models'

// Redux types

export enum InvoiceActions {
  SET_INVOICE_DATA = '@@travel/SET_INVOICE_DATA',
  REMOVE_INVOICE_DATA = '@@travel/REMOVE_INVOICE_DATA'
}

export interface SetInvoiceData extends Action {
  type: InvoiceActions.SET_INVOICE_DATA
  payload: Partial<Invoice>
}

export interface RemoveInvoiceData extends Action {
  type: InvoiceActions.REMOVE_INVOICE_DATA
}

export interface InvoiceState {
  currentInvoice: Readonly<Invoice>
}

type InvoiceExtraArg = undefined

export type InvoiceAction = SetInvoiceData | RemoveInvoiceData

export type InvoiceActionThunk<R> = ThunkAction<R, ApplicationState, InvoiceExtraArg, InvoiceAction>

export type InvoiceThunkDispatch = ThunkDispatch<ApplicationState, InvoiceExtraArg, Action>

export type AsyncInvoiceAction<T> = InvoiceActionThunk<Promise<T>>
