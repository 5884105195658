import Stop from './Stop'
import Price from './Price'
import AvailableTicket from './AvailableTicket'
import Ticket from './Ticket'
import { ScheduleDiscount } from './ScheduleDiscount'

export default class Schedule {
  public schedule_route_destination_id: number = 0
  public schedule_route_id: number = 0
  public config_destination_id: number = 0
  public departure_date: string = ''
  public arrival_date: string = ''
  public order_origin: number = 0
  public order_destiny: number = 0
  public terminal_origin_id: number = 0
  public terminal_destiny_id: number = 0
  public travel_time: string = ''
  public discount_tickets: boolean = false
  public config_vehicle_id: number = 0
  public economic_number: string = ''
  public seatings: number = 0
  public vehicle_id: number = 0
  public config_schedule_id: number = 0
  public config_route_id: number = 0
  public characteristics: any[] = []
  public stops: Stop[] = []
  public prices: Price[] = []
  public available_seatings: number = 0
  public destination_status: 'loading' | 'scheduled' = 'scheduled'
  public available_tickets: AvailableTicket[] = []
  public discount?: ScheduleDiscount
  public status_hide: number = 0

  constructor(schedule?: Partial<Schedule>) {
    Object.assign(this, schedule)
  }

  travelCost(stl: Ticket[], isReturn: boolean) {
    const ticket_type_route: 'regreso' | 'ida' = isReturn ? 'regreso' : 'ida'
    return this.getAmount(stl, 'total', false, ticket_type_route)
  }

  travelCostWithoutDiscount(stl: Ticket[]) {
    if (this.discount) {
      return this.getAmount(stl, 'total', true)
    }
    return this.getAmount(stl, 'amount')
  }

  travelDiscount(stl: Ticket[]) {
    return this.getAmount(stl, 'discount')
  }

  getAmount(stl: Ticket[], type: 'total' | 'amount' | 'discount' = 'total', useDefault?: boolean, ticket_type_route?: 'ida' | 'regreso') {
    if (!this.prices.length) {
      return 0
    }
    return stl.reduce((acu, st) => {
      let config_price
      if (!useDefault && this.discount) {
        config_price = this.discount.prices_discount.find(p => p.special_ticket_id === st.id && p.ticket_type_route === ticket_type_route)
      } else {
        config_price = this.prices.find(p => p.special_ticket_id === st.id)
      }
      if (!config_price) {
        return acu
      }
      let amount = config_price.total_amount
      if (type === 'amount') {
        amount = config_price.amount
      }
      if (type === 'discount') {
        amount = config_price.discount
      }
      return acu + amount * st.value
    }, 0)
  }
}
