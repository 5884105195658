import { Action } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

export interface Alliances {
  id?: number
  name: string
  description: string
  init_at: string
  valid_at: string
  alliance_category_id: number
  cities: any
  services: any
  link: string
  img_file: string
}

export interface AlliancesResponse {
  count: number
  items: number
  results: Alliances[]
}

export interface AllianceServices {
  id?: number
  name: string
  description: string
  unit_price: number
}

export interface AllianceCategory {
  id?: number
  name: string
}

export enum AllianceActions {
  GET_ALLIANCES = '@@alliances/GET_ALLIANCES',
  GET_SERVICES = '@@alliances/GET_SERVICES',
  GET_CATEGORIES = '@@alliances/GET_CATEGORIES'
}

export interface GetAlliances extends Action {
  type: AllianceActions.GET_ALLIANCES
  payload: AlliancesResponse
}

export interface GetAllianceServices extends Action {
  type: AllianceActions.GET_SERVICES
  payload: AllianceServices[]
}

export interface GetAllianceCategories extends Action {
  type: AllianceActions.GET_CATEGORIES
  payload: AllianceCategory[]
}

export interface AllianceState {
  alliances: Readonly<AlliancesResponse>
  allianceServices: Readonly<AllianceServices[]>
  allianceCategory: Readonly<AllianceCategory[]>
}

type AllianceExtraArg = undefined

export type AllianceAction = GetAlliances | GetAllianceServices | GetAllianceCategories

export type AlliancesActionThunk<R> = ThunkAction<R, AllianceState, AllianceExtraArg, AllianceAction>

export type AllianceThunkDispatch = ThunkDispatch<AllianceState, AllianceExtraArg, Action>

export type AsyncAllianceAction<T> = AlliancesActionThunk<Promise<T>>
