import { Schedules } from 'modules/terminals/models/schedules'
import CommonFields from 'shared/models/CommonFields'

export default class Terminal implements CommonFields {
  public id: number = 0
  public branch_office_type: 'T' = 'T'
  public city_name: string = ''
  public prefix: string = ''
  public name: string = ''
  public description: string = ''
  public address: string = ''
  public street_id: number = 0
  public no_ext: string = ''
  public no_int?: string = ''
  public suburb_id: number = 0
  public city_id: number = 0
  public county_id: number = 0
  public state_id: number = 0
  public country_id: number = 0
  public zip_code: number = 0
  public reference: string = ''
  public latitude: string = ''
  public longitude: string = ''
  public manager_id: number = 0
  public time_zone?: any
  public time_checkpoint: number = 0
  public time_manteinance: number = 0
  public business_segment?: any
  public status: number = 0
  public created_by: number = 0
  public updated_by: number = 0
  public created_at: string = ''
  public updated_at: string = ''
  public phone: string = ''
  public schedules: Schedules[] = []

  constructor(terminal?: Partial<Terminal>) {
    Object.assign(this, terminal)
  }
}
