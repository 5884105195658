import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { Action } from 'redux'
import { ApplicationState } from 'store'
import { User } from 'auth/models'

export enum UserActions {
  SET_USER_DATA = '@@user/SET_USER_DATA',
  REMOVE_USER_DATA = '@@user/REMOVE_USER_DATA'
}

export interface SetUserData extends Action {
  type: UserActions.SET_USER_DATA
  payload: Partial<User>
}

export interface RemoveUserData extends Action {
  type: UserActions.REMOVE_USER_DATA
}

export interface IUserState extends Readonly<User> {}

export type UserState = IUserState | null

export type UserAction = SetUserData | RemoveUserData

type UserExtraArg = undefined

export type UserActionThunk<R> = ThunkAction<R, ApplicationState, undefined, UserAction>

export type UserThunkDispatch = ThunkDispatch<ApplicationState, UserExtraArg, Action>

export type AsyncUserAction<T> = UserActionThunk<Promise<T>>
