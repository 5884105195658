import config from '../config/Config'

export type PixelEvent = 'CompleteRegistration' | 'Search' | 'Purchase' | 'InitiateCheckout' | 'AddToCart' | 'FindLocation' | 'Lead'
export interface FacebookPixelDef {
  pageView: () => void
  track: (event: PixelEvent, data: object) => void
}

export type FacebookPixel = FacebookPixelDef | null

let ReactPixel: FacebookPixel = null

export function setupPixel() {
  const { facebookPixelId } = config
  if (!ReactPixel && facebookPixelId) {
    const ReactPixelLib = require('react-facebook-pixel')
    ReactPixelLib.default.init(facebookPixelId)
    ReactPixel = {
      pageView: () => {
        ReactPixelLib.default.pageView()
      },
      track: (event: PixelEvent, data: object) => {
        ReactPixelLib.default.track(event, data)
      }
    }
  }

  return ReactPixel
}
