import { Reducer } from 'redux'
import { CityState, CityActions } from './types'

// Type-safe initialState!
const initialState: CityState = {
  cities: []
}

const reducer: Reducer<CityState> = (city = initialState, action) => {
  switch (action.type) {
    case CityActions.GET_CITIES: {
      return {
        ...city,
        cities: action.payload
      }
    }
    default: {
      return city
    }
  }
}

export { reducer as cityReducer }
