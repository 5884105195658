import _ from 'lodash'

export const guid = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  }

  return (s4() + s4()).toString()
}

export const isExisty = (value: any) => {
  return !(typeof value === 'undefined' || value === null)
}

export const validValue = (value: number, min?: number, max?: number) => {
  let val = value

  if (!(min && max)) {
    return val
  }

  if (min > max) {
    throw 'min value is greater than max value'
  }

  val = isExisty(min) ? Math.max(val, min) : val
  val = isExisty(max) ? Math.min(val, max) : val
  return val
}

export const isFunction = <T extends {}>(value: T): value is IsFunction<T> => typeof value === 'function'

export const queryParams = <T>(location?: Location): Partial<T> => {
  const loc = location || window.location
  const search = decodeURI(loc.search)
  const params = search.slice(1)
  if (!params) {
    return {}
  }
  const parts = params.split('&')
  return parts.reduce<Partial<T> & IndexSignature>((acu, p) => {
    const [key, value] = p.split('=')
    // @ts-ignore
    acu[key] = value
    return acu
  }, {})
}

export const capitalize = (text: string, separator: string = ' ') => {
  let parts = text.toLocaleLowerCase().split(separator)
  parts = parts.map(p => p.charAt(0).toUpperCase() + p.slice(1))
  return parts.join(separator)
}

// export const PASSWORD = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`'<>|{}:;!.?\"()\[\]-]{8,}$/
export const PASSWORD = /^(?=.*\d)(?=.*[a-z])[\w~@#$%^&*+=`'<>|{}:;!.?\"()\[\]-]{8,}$/

// RFC pattern for legal entities (personas morales)
const rfcPatternPM = /^(([A-ZÑ&]{3})([0-9]{2})(0[13578]|1[02])(([0][1-9]|[12][\d])|3[01])([A-Z0-9]{3}))|(([A-ZÑ&]{3})([0-9]{2})(0[13456789]|1[012])(([0][1-9]|[12][\d])|3[0])([A-Z0-9]{3}))|(([A-ZÑ&]{3})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\d])([A-Z0-9]{3}))|(([A-ZÑ&]{3})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$/

// RFC pattern for individuals (personas físicas)
const rfcPatternPF = /^(([A-ZÑ&]{4})([0-9]{2})(0[13578]|1[02])(([0][1-9]|[12][\d])|3[01])([A-Z0-9]{3}))|(([A-ZÑ&]{4})([0-9]{2})(0[13456789]|1[012])(([0][1-9]|[12][\d])|3[0])([A-Z0-9]{3}))|(([A-ZÑ&]{4})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\d])([A-Z0-9]{3}))|(([A-ZÑ&]{4})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$/

export const validRFC = (rfc: string, acceptGeneric = true): boolean => {
  // Check if RFC is one of the authorized generic values and should be accepted
  if ((rfc === 'XAXX010101000' || rfc === 'XEXX010101000') && acceptGeneric) {
    return true
  }

  // Reject RFCs following the pattern XXXX101010XXX, except the authorized generic
  if (/^[A-Z]{3,4}101010[A-Z\d]{3}$/.test(rfc)) {
    return false
  }

  const re = rfc.length === 12 ? rfcPatternPM : rfcPatternPF
  return re.test(rfc)
}

export const parsePhone = (str: string) => {
  const re = /(\d\d\d)(\d\d\d)(\d\d\d\d)/
  const subst = '($1) $2-$3'
  const result = str.replace(re, subst)
  return result
}
