import { TravelAction, TravelActions, AsyncTravelAction, TravelActionThunk } from './types'
import { GenericService } from 'core/services'
import { Terminal, SpecialTicket, Schedule, Travel } from 'modules/travel/models'
import { TravelService } from 'modules/travel/services'
import { getTravel } from './selectors'
import { AvailableRoutesQuery, PriceListBody } from 'modules/travel/services/TravelService/types'
import moment from 'moment'
import { Config } from 'core'

export const fetchCorp = (): AsyncTravelAction<Terminal[]> => {
  return dispatch => {
    return GenericService.list<Terminal[]>('branchoffices', { where: `branch_office_type='A' AND status = 1` }).then(corp => {
      return GenericService.list<Terminal[]>('branchoffices', { where: `branch_office_type='T' AND status = 1  ` }).then(terminals => {
        terminals.forEach(element => {
          corp.push(element)

          if (terminals.findIndex(t => t.prefix === 'LMM02') >= 0) {
            terminals.splice(
              terminals.findIndex(t => t.prefix === 'LMM02'),
              1
            )
          }
        }),
          dispatch({
            type: TravelActions.GET_CORP,
            payload: corp
          })
        return Promise.resolve(corp)
      })
    })
  }
}
export const fetchTerminals = (): AsyncTravelAction<Terminal[]> => {
  return dispatch => {
    return GenericService.list<Terminal[]>('branchoffices', { where: `branch_office_type='T' AND status = 1 ` }).then(terminals => {
      dispatch({
        type: TravelActions.GET_TERMINALS,
        payload: terminals
      })
      return Promise.resolve(terminals)
    })
  }
}

export const fetchSpecialTickets = (): AsyncTravelAction<SpecialTicket[]> => {
  return dispatch => {
    return GenericService.list<SpecialTicket[]>('specialTickets', {
      where: `base = FALSE AND status = 1`,
      search: 'web',
      searchKeys: 'origin_allowed'
    }).then(tickets => {
      const defaultTicket = tickets.find(t => t.is_default)
      if (defaultTicket) {
        const special_tickets = [defaultTicket, ...tickets.filter(t => !t.is_default && t.status === 1)]
        dispatch({
          type: TravelActions.GET_SPECIAL_TICKETS,
          payload: special_tickets
        })
        return Promise.resolve(tickets)
      }
      return Promise.resolve([])
    })
  }
}

export const fetchAvailableRoutes = (isReturn?: boolean): AsyncTravelAction<Schedule[]> => {
  return (dispatch, getState) => {
    const travel = getTravel(getState())

    let service: (query: any) => Promise<Schedule[]>
    const query = parseQuery(travel, isReturn)
    const usePriceList = !travel.departure.date || (isReturn && travel.isOpen)
    if (usePriceList) {
      service = TravelService.getPriceList
    } else {
      service = TravelService.availabeRoutes
    }

    return service(query).then(routes => {
      let avRoutes = routes
      if (!usePriceList) {
        avRoutes = routes.filter(r => r.destination_status === 'scheduled' && r.prices && r.prices.length > 0)
        setAvailableTickets(
          travel.tickets.map(t => ({ id: t.id, number: t.value })),
          avRoutes
        )
        avRoutes.sort((a, b) => {
          const dateA = moment(a.departure_date).valueOf()
          const dateB = moment(b.departure_date).valueOf()
          return dateA - dateB
        })
      }
      let rule: string
      if (travel.travelType === 2) rule = 'boardingpass_sencillo'
      if (travel.travelType === 3) {
        rule = !travel.isOpen ? 'boardingpass_redondo' : 'boardingpass_abierto'
      }
      avRoutes.forEach(av => {
        if (av.discount && av.discount.rule !== rule) delete av.discount
      })
      dispatch({
        type: TravelActions.GET_AVAILABLE_ROUTES,
        payload: avRoutes
      })
      return Promise.resolve(avRoutes)
    })
  }
}

export const removeTravelData = (): TravelAction => {
  return {
    type: TravelActions.REMOVE_TRAVEL_DATA
  }
}

export const removeDiscount = (): TravelAction => {
  return {
    type: TravelActions.REMOVE_TRAVEL_DISCOUNT
  }
}

export const setTravelData = (travel: Partial<Travel>): TravelActionThunk<void> => {
  return (dispatch, getState) => {
    const state = getState()
    const travelState = state.travel.currentTravel
    if (travelState) {
      if (travel.tickets) {
        travel.seatings = travel.tickets.reduce((acu, next) => acu + (next.value || 0), 0)
      }
      const t = {
        ...travelState,
        ...travel,
        departure: {
          ...travelState.departure,
          ...travel.departure
        },
        return: {
          ...travelState.return,
          ...travel.return
        }
      }
      return dispatch({
        type: TravelActions.SET_TRAVEL_DATA,
        payload: t
      })
    }
  }
}

function setAvailableTickets(stl: { id: number; number: number }[], routes: Schedule[]) {
  routes.forEach(r => {
    const availableTickets = r.available_tickets
    stl.forEach(st => {
      const ticket = availableTickets.find(at => at.id === st.id)

      if (ticket) {
        if (st.number === 0) {
          ticket.isAvailable = true
          return
        }
        ticket.isAvailable = st.number <= ticket.available_tickets
      }
    })
  })
}
const parseQuery = (travel: Travel, isReturn?: boolean): AvailableRoutesQuery | PriceListBody => {
  const { origin, destination, departure, return: returnObj, tickets, isOpen, travelType } = travel
  let dt = departure.date && departure.date.format('YYYY-MM-DD')
  let ht
  let LMM01
  if (origin.id === 8 && isReturn) {
    LMM01 = 1
  } else {
    LMM01 = 0
  }
  if (isReturn && departure.date && returnObj.date && departure.schedule) {
    dt = returnObj.date && returnObj.date.format('YYYY-MM-DD')
    if (departure.date.isSame(returnObj.date, 'day')) {
      ht = moment(departure.schedule.arrival_date)
        .utcOffset(0)
        .format('HH:mm:ss')
    }
  } else if (departure.date && departure.date.isSame(moment(), 'day')) {
    ht = moment().format('HH:mm:ss')
  }

  if (!dt || (isReturn && isOpen)) {
    return {
      LMM01,
      terminal_origin: isReturn ? destination.id : origin.id,
      terminal_destiny: isReturn ? origin.id : destination.id
    }
  }

  let rule = ''
  if (travelType === 2) rule = 'boardingpass_sencillo'
  if (travelType === 3) {
    rule = !isOpen ? 'boardingpass_redondo' : 'boardingpass_abierto'
  }

  return {
    LMM01,
    dt,
    ht,
    rule,
    toid: isReturn ? destination.id : origin.id,
    tdid: isReturn ? origin.id : destination.id,
    stl: tickets.map(t => ({ id: t.id, number: t.value })),
    purchase_origin: Config.origin,
    customer_id: travel.customer && travel.customer.id,
    is_return: returnObj ? true : false,
    origen_request: 'site',
    ticket_type_route: isReturn ? 'regreso' : 'ida'
  }
}
