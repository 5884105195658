import { ApiService, GenericService } from 'core/services'
import { Config } from 'core'
import { User } from 'auth/models'
import { LoginBody, TokenData, LoginResponse } from './types'

import jwtDecode from 'jwt-decode'
import { Customer } from 'modules/travel/models'
import { TravelHistoryData, TravelHistoryItem } from 'auth/pages/TravelHistory/types'

import moment from 'moment'

class AuthService {
  public static instance: AuthService

  constructor() {
    if (AuthService.instance) {
      return AuthService.instance
    }
    AuthService.instance = this
  }

  setAccessToken = (token: string) => {
    if (token) {
      if (typeof window !== 'undefined') {
        sessionStorage.setItem(Config.xAccessToken, token)
        const tokenData = this.getTokenData()
        if (tokenData) {
          const now = Date.now()
          const expiresAt = JSON.stringify(tokenData.exp + now)
          sessionStorage.setItem(Config.xExpiresToken, expiresAt)
        }
      }
    }
  }

  getAccessToken() {
    if (typeof window !== 'undefined') {
      return sessionStorage.getItem(Config.xAccessToken)
    }
  }

  isAuthenticated = () => {
    // Check whether the current time is past the
    // access token's expiry time
    if (typeof window !== 'undefined') {
      const exp = sessionStorage.getItem(Config.xExpiresToken)
      if (!exp) {
        this.logout()
        return false
      }
      const expiresAt = JSON.parse(exp)
      const isNotExpired = new Date().getTime() < expiresAt
      if (isNotExpired) {
        return true
      }
    }
    this.logout()
    return false
  }

  getTokenData = () => {
    let decoded: TokenData
    const token = this.getAccessToken()
    if (!token) {
      return null
    }
    decoded = jwtDecode(token)
    if (!decoded) {
      return null
    }
    decoded.sub = JSON.parse(decoded.sub)
    return decoded
  }

  login(email: string, pass: string): Promise<User> {
    let user: User
    return ApiService.post<LoginResponse, LoginBody>('auth/loginCustomer', { email, pass })
      .then(resp => {
        this.setAccessToken(resp.accessToken.token)
        user = new User(resp)
        return this.getCustomer(user.id)
      })
      .then(customer => {
        if (!customer) {
          throw 'Customer not found'
        }
        user.name = `${customer.first_name} ${customer.last_name}`
        user.phone = customer.phone
        user.customer = customer
        return Promise.resolve(user)
      })
      .catch(err => {
        this.logout()
        return Promise.reject(err)
      })
  }

  logout = () => {
    sessionStorage.removeItem(Config.xAccessToken)
    sessionStorage.removeItem(Config.xExpiresToken)
  }

  getUserData(): Promise<User> {
    const tokenData = this.getTokenData()
    if (!tokenData) {
      return Promise.reject({ message: 'No token data' })
    }
    let user: User
    return ApiService.get<User>(`users/${tokenData.sub}`)
      .then(resp => {
        user = new User(resp)
        return this.getCustomer(user.id)
      })
      .then(customer => {
        if (!customer) {
          throw 'Customer not found'
        }
        user.name = `${customer.first_name} ${customer.last_name}`
        user.phone = customer.phone
        user.customer = customer
        return Promise.resolve(user)
      })
      .catch(err => {
        this.logout()
        return Promise.reject(err)
      })
  }

  getCustomer(id: number): Promise<Customer> {
    return GenericService.list<Customer[]>('customers', { where: `user_id=${id}` }).then(customers => {
      const customer = customers[0]
      return Promise.resolve(customer)
    })
  }

  confirmCustomer(token: string): Promise<void> {
    return ApiService.get(`customers/confirmAccount/${token}`)
  }

  resetPassword(body: { actual_pass: string; new_pass: string }) {
    return ApiService.post('auth/resetPass', body)
  }

  updateUser(user: User) {
    return ApiService.put('customers', user)
  }

  getTravelHistory(param: string): Promise<TravelHistoryItem[]> {
    return ApiService.get<TravelHistoryData[]>(`boardingPasses/travelsDetail/${param}`).then(travels => {
      const resultTravels = travels.reduce((acu, t) => {
        t.routes.forEach(route => {
          const origin = route.origin_name
          const destination = route.destiny_name
          const departure = parseDate0(route.travel_date)
          const arrival = parseDate0(route.arrival_date)
          acu.push({
            origin,
            destination,
            departure,
            arrival,
            id: route.id,
            reservation_code: t.reservation_code,
            price: t.total_amount
          })
        })
        return acu
      }, [] as TravelHistoryItem[])
      return Promise.resolve(resultTravels)
    })
  }
}

function parseDate0(date: string) {
  return moment(date)
    .utcOffset(0)
    .format('L hh:mm a')
}

const instance = new AuthService()

Object.freeze(instance)

export default instance
