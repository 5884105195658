import axios, { AxiosInstance } from 'axios'
import { Config } from 'core'
import { AuthService } from 'auth/services'
const httpConfig = Config.httpConfig
const instance: AxiosInstance = axios.create({
  ...httpConfig,
  headers: {
    'Content-Type': 'application/json'
  }
})

// request header
instance.interceptors.request.use(
  config => {
    const token = AuthService.getAccessToken()
    if (token) {
      config.headers.Authorization = token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

export default instance
