import { Component } from 'react'
import { AuthService } from 'auth/services'
import { AuthProps } from './types'

export class Auth extends Component<AuthProps> {
  componentDidMount() {
    if (AuthService.isAuthenticated()) {
      AuthService.getUserData()
        .then(user => {
          this.props.setUser(user)
        })
        .catch(console.warn)
    }
  }

  render() {
    const { children } = this.props
    return children
  }
}

export default Auth
