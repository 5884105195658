import { InvoiceAction, InvoiceActions } from './types'
import { Invoice } from 'modules/invoice/models'

export const removeInvoiceData = (): InvoiceAction => {
  return {
    type: InvoiceActions.REMOVE_INVOICE_DATA
  }
}

export const setInvoiceData = (invoice: Partial<Invoice>): InvoiceAction => {
  return {
    type: InvoiceActions.SET_INVOICE_DATA,
    payload: { ...invoice }
  }
}
