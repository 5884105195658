// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-alianzas-tsx": () => import("./../../../src/pages/alianzas.tsx" /* webpackChunkName: "component---src-pages-alianzas-tsx" */),
  "component---src-pages-avisolegal-tsx": () => import("./../../../src/pages/avisolegal.tsx" /* webpackChunkName: "component---src-pages-avisolegal-tsx" */),
  "component---src-pages-avisoprivacidad-tsx": () => import("./../../../src/pages/avisoprivacidad.tsx" /* webpackChunkName: "component---src-pages-avisoprivacidad-tsx" */),
  "component---src-pages-baseconocimientos-tsx": () => import("./../../../src/pages/baseconocimientos.tsx" /* webpackChunkName: "component---src-pages-baseconocimientos-tsx" */),
  "component---src-pages-borealstudio-tsx": () => import("./../../../src/pages/borealstudio.tsx" /* webpackChunkName: "component---src-pages-borealstudio-tsx" */),
  "component---src-pages-cambiarcontrasena-tsx": () => import("./../../../src/pages/cambiarcontraseña.tsx" /* webpackChunkName: "component---src-pages-cambiarcontrasena-tsx" */),
  "component---src-pages-centrales-tsx": () => import("./../../../src/pages/centrales.tsx" /* webpackChunkName: "component---src-pages-centrales-tsx" */),
  "component---src-pages-compraboleto-tsx": () => import("./../../../src/pages/compraboleto.tsx" /* webpackChunkName: "component---src-pages-compraboleto-tsx" */),
  "component---src-pages-confirmarcuenta-tsx": () => import("./../../../src/pages/confirmarcuenta.tsx" /* webpackChunkName: "component---src-pages-confirmarcuenta-tsx" */),
  "component---src-pages-contacto-tsx": () => import("./../../../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-cotizarenvio-tsx": () => import("./../../../src/pages/cotizarenvio.tsx" /* webpackChunkName: "component---src-pages-cotizarenvio-tsx" */),
  "component---src-pages-descarga-tsx": () => import("./../../../src/pages/descarga.tsx" /* webpackChunkName: "component---src-pages-descarga-tsx" */),
  "component---src-pages-descargaqr-tsx": () => import("./../../../src/pages/descargaqr.tsx" /* webpackChunkName: "component---src-pages-descargaqr-tsx" */),
  "component---src-pages-destinos-tsx": () => import("./../../../src/pages/destinos.tsx" /* webpackChunkName: "component---src-pages-destinos-tsx" */),
  "component---src-pages-editarperfil-tsx": () => import("./../../../src/pages/editarperfil.tsx" /* webpackChunkName: "component---src-pages-editarperfil-tsx" */),
  "component---src-pages-estatuscorrida-tsx": () => import("./../../../src/pages/estatuscorrida.tsx" /* webpackChunkName: "component---src-pages-estatuscorrida-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-graciasporsucompra-tsx": () => import("./../../../src/pages/graciasporsucompra.tsx" /* webpackChunkName: "component---src-pages-graciasporsucompra-tsx" */),
  "component---src-pages-historialviajes-tsx": () => import("./../../../src/pages/historialviajes.tsx" /* webpackChunkName: "component---src-pages-historialviajes-tsx" */),
  "component---src-pages-imprimirfactura-tsx": () => import("./../../../src/pages/imprimirfactura.tsx" /* webpackChunkName: "component---src-pages-imprimirfactura-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-iniciarsesion-tsx": () => import("./../../../src/pages/iniciarsesion.tsx" /* webpackChunkName: "component---src-pages-iniciarsesion-tsx" */),
  "component---src-pages-nosotros-tsx": () => import("./../../../src/pages/nosotros.tsx" /* webpackChunkName: "component---src-pages-nosotros-tsx" */),
  "component---src-pages-politicasviaje-tsx": () => import("./../../../src/pages/politicasviaje.tsx" /* webpackChunkName: "component---src-pages-politicasviaje-tsx" */),
  "component---src-pages-rastreo-tsx": () => import("./../../../src/pages/rastreo.tsx" /* webpackChunkName: "component---src-pages-rastreo-tsx" */),
  "component---src-pages-recoverpassword-tsx": () => import("./../../../src/pages/recoverpassword.tsx" /* webpackChunkName: "component---src-pages-recoverpassword-tsx" */),
  "component---src-pages-registrousuario-tsx": () => import("./../../../src/pages/registrousuario.tsx" /* webpackChunkName: "component---src-pages-registrousuario-tsx" */),
  "component---src-pages-restriccionesenvio-tsx": () => import("./../../../src/pages/restriccionesenvio.tsx" /* webpackChunkName: "component---src-pages-restriccionesenvio-tsx" */),
  "component---src-pages-solicitarfactura-tsx": () => import("./../../../src/pages/solicitarfactura.tsx" /* webpackChunkName: "component---src-pages-solicitarfactura-tsx" */),
  "component---src-pages-terminos-tsx": () => import("./../../../src/pages/terminos.tsx" /* webpackChunkName: "component---src-pages-terminos-tsx" */),
  "component---src-pages-tours-tsx": () => import("./../../../src/pages/tours.tsx" /* webpackChunkName: "component---src-pages-tours-tsx" */),
  "component---src-pages-tutorialregistro-tsx": () => import("./../../../src/pages/tutorialregistro.tsx" /* webpackChunkName: "component---src-pages-tutorialregistro-tsx" */),
  "component---src-pages-viaje-tsx": () => import("./../../../src/pages/viaje.tsx" /* webpackChunkName: "component---src-pages-viaje-tsx" */)
}

