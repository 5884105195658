import Terminal from './Terminal'
import Ticket from './Ticket'
import Customer from './Customer'
import ScheduleInfo from './ScheduleInfo'
import { Schedule } from '.'
import Passenger from './Passenger'
import { InitTravelBody, TravelPassenger, TravelTicket, TravelRoute, CalculateDiscountResponse } from '../services/TravelService/types'
import moment from 'moment'
import { guid } from 'core/utils'
import { Config } from 'core'

interface PaymentDetail {
  id: number
  number: number
  desc: string
  amount: number
  total: number
}

export default class Travel {
  static initBody = (travel: Travel): InitTravelBody => {
    const { departure, return: returnTravel, customer } = travel
    const passengers: TravelPassenger[] = travel.passengers.map(passenger => {
      const departureTicket: TravelTicket = {}
      if (departure.schedule) {
        if (travel.isOpen && !travel.departure.date) {
          departureTicket.config_destination_id = departure.schedule.config_destination_id
        } else if (passenger.departure_seat) {
          departureTicket.schedule_route_destination_id = departure.schedule.schedule_route_destination_id
          departureTicket.seat = passenger.departure_seat.seat
        }
      }
      const p: TravelPassenger = {
        first_name: passenger.first_name,
        last_name: passenger.last_name,
        gender: passenger.gender,
        hash: passenger.hash,
        principal_passenger: passenger.principal_passenger,
        need_preferential: passenger.need_preferential,
        is_customer: passenger.is_customer,
        special_ticket_id: (passenger.special_ticket && passenger.special_ticket.id) || 0,
        birthday: (passenger.birthday && passenger.birthday.format('YYYY-MM-DD')) || '',
        tickets: [departureTicket]
      }

      if (travel.travelType === 3) {
        const returnTicket: TravelTicket = {}
        if (returnTravel.schedule) {
          if (travel.isOpen) {
            returnTicket.config_destination_id = returnTravel.schedule.config_destination_id
          } else if (passenger.return_seat) {
            returnTicket.schedule_route_destination_id = returnTravel.schedule.schedule_route_destination_id
            returnTicket.seat = passenger.return_seat.seat
          }
        }
        p.tickets.push(returnTicket)
      }
      return p
    })

    const departureRoute: TravelRoute = {
      ticket_type_route: 1,
      order_route: 1
    }
    if (departure.schedule) {
      if (travel.isOpen && !travel.departure.date) {
        departureRoute.config_destination_id = departure.schedule.config_destination_id
      } else {
        departureRoute.schedule_route_destination_id = departure.schedule.schedule_route_destination_id
      }
    }

    let ticket_type = travel.travelType

    if (travel.isOpen) {
      if (ticket_type === 2) {
        ticket_type = 1
      } else {
        ticket_type = 4
      }
    }

    const body: InitTravelBody = {
      passengers,
      ticket_type,
      seatings: travel.seatings,
      purchase_origin: Config.origin,
      travel_date:
        departure.schedule &&
        moment(departure.schedule.departure_date)
          .utcOffset(0)
          .format('YYYY-MM-DD HH:mm:ss'),
      routes: [departureRoute]
    }

    if (customer) {
      body.customer_id = customer.id
      body.email = customer.email
      body.phone = customer.phone
    } else if (travel.email) {
      body.email = travel.email
      body.phone = travel.phone
    }

    if (travel.travelType === 3) {
      const returnRoute: TravelRoute = {
        ticket_type_route: 2,
        order_route: 1
      }
      if (returnTravel.schedule) {
        if (travel.isOpen) {
          returnRoute.config_destination_id = returnTravel.schedule.config_destination_id
        } else {
          body.travel_return_date = moment(returnTravel.schedule.departure_date)
            .utcOffset(0)
            .format('YYYY-MM-DD HH:mm:ss')
          returnRoute.schedule_route_destination_id = returnTravel.schedule.schedule_route_destination_id
        }
      }
      body.routes.push(returnRoute)
    }

    return body
  }

  static createPassengers = (travel: Travel) => {
    return travel.tickets.reduce<Passenger[]>((list, st) => {
      const ax = []
      const special_ticket = st.ticket
      if (special_ticket) {
        for (let i = 0, j = st.value; i < j; i += 1) {
          ax.push(
            new Passenger({
              special_ticket,
              first_name: '',
              last_name: '',
              birthday: null,
              hash: guid(),
              min_date: moment().subtract(special_ticket.younger_than, 'years'),
              max_date: moment().subtract(special_ticket.older_than, 'years'),
              is_kid: special_ticket.younger_than < 18,
              need_preferential: false,
              principal_passenger: false,
              is_customer: false
            })
          )
        }
      }
      return list.concat(ax)
    }, [])
  }

  public id: number = 0
  public travelType: number = 0
  public reservationCode?: string
  public origin: Terminal = new Terminal()
  public LMM01?: number
  public destination: Terminal = new Terminal()
  public tickets: Ticket[] = []
  public passengers: Passenger[] = []
  public seatings: number = 0
  public customer?: Customer
  public departure: ScheduleInfo = new ScheduleInfo()
  public return: ScheduleInfo = new ScheduleInfo()
  public step: number = -1
  public total_amount?: number
  public email?: string
  public phone?: string
  public isOpen?: boolean
  public discountInfo?: CalculateDiscountResponse
  public defaultDiscount?: string

  constructor(travel?: Partial<Travel>) {
    Object.assign(this, travel)
  }

  travelCost() {
    if (this.discountInfo) {
      return this.discountInfo.service.total_amount
    }
    return this.getAmount('total')
  }

  travelAmount() {
    if (this.discountInfo) {
      return this.discountInfo.service.amount
    }
    return this.getAmount('amount')
  }

  travelDiscount() {
    if (this.discountInfo) {
      return this.discountInfo.service.discount
    }
    return this.getAmount('discount')
  }

  getTicketsTotal = function(id: number, num: number, schedule?: Schedule) {
    if (!schedule) {
      return 0
    }
    const config_price = schedule.prices.find(p => p.special_ticket_id === id)
    if (config_price) {
      return num * config_price.total_amount
    }
    return 0
  }

  getDetails(travelType: 'ida' | 'regreso') {
    const groups: IndexSignature<PaymentDetail> = {}
    this.passengers.forEach(passenger => {
      if (passenger.boardingpass && passenger.special_ticket) {
        const stid = passenger.special_ticket.id
        const ticket = passenger.boardingpass.tickets.find(t => t.travelType === travelType)
        if (ticket) {
          let total_amount = ticket.total_amount
          if (this.discountInfo) {
            const discountTicket = this.discountInfo.products.find(p => p.id === ticket.id)
            total_amount = (discountTicket && discountTicket.total_amount) || 0
          }
          if (!groups[stid]) {
            groups[stid] = {
              amount: 0,
              id: stid,
              number: 1,
              desc: passenger.special_ticket.name,
              total: total_amount
            }
          } else {
            const group = groups[stid]
            group.number += 1
            group.total += total_amount
          }
        }
      }
    })
    return Object.keys(groups).map(k => groups[k])
  }

  getPaymentDetail() {
    const detail: { departure: PaymentDetail[]; return: PaymentDetail[] } = {
      departure: this.getDetails('ida'),
      return: []
    }
    if (this.travelType === 3) {
      detail.return = this.getDetails('regreso')
    }
    return detail
  }

  getAmount(type: 'total' | 'amount' | 'discount') {
    let total = 0
    if (this.departure.schedule) {
      total += new Schedule(this.departure.schedule).getAmount(this.tickets, type, false, 'ida')
    }

    if (this.travelType === 3 && this.return.schedule) {
      total += new Schedule(this.return.schedule).getAmount(this.tickets, type, false, 'regreso')
    }

    return total
  }
}
