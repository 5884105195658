import Auth from './Auth'
import { connect } from 'react-redux'
import { UserThunkDispatch, setUserData } from 'store/user'
import { User } from 'auth/models'

export const mapDispatch = (dispatch: UserThunkDispatch) => ({
  setUser: (user: Partial<User>) => dispatch(setUserData(user))
})

export default connect(null, mapDispatch)(Auth)
