import { isExisty } from './CommonUtils'

export const cssBreakPoints = {
  xs: '@media (max-width: 575.98px)',
  sm: '@media (min-width: 576px) and (max-width: 767.98px)',
  'lt-sm': '@media (max-width: 767.98px)',
  md: '@media (min-width: 768px) and (max-width: 991.98px)',
  lg: '@media (min-width: 992px) and (max-width: 1199.98px)',
  xl: '@media (min-width: 1200px)'
}

export const currency = new Intl.NumberFormat('es-MX', {
  style: 'currency',
  currency: 'MXN',
  minimumFractionDigits: 2
})

export const scrollTop = (top: number, behavior: ScrollBehavior) => {
  if (isExisty(window)) {
    window.scrollTo({
      behavior,
      top
    })
  }
}

export const clearScrolls = (path?: string) => {
  const reg = new RegExp(`^@@scroll|/${path || ''}`)
  if (isExisty(window)) {
    Object.keys(sessionStorage).forEach(key => {
      if (reg.test(key)) {
        sessionStorage.removeItem(key)
      }
    })
  }
}
