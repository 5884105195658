import Config from '../../config/Config'

class ConektaService {
  public static instance: ConektaService

  conekta: any

  constructor() {
    if (ConektaService.instance) {
      return ConektaService.instance
    }
    ConektaService.instance = this
  }

  getConekta(): Promise<any> {
    return new Promise((resolve, _reject) => {
      const script = document.createElement('script')
      script.src = 'https://cdn.conekta.io/js/latest/conekta.js'
      script.async = true
      if (!this.conekta) {
        script.addEventListener(
          'load',
          _e => {
            this.conekta = window.Conekta
            if (Config.conektaPublicKey) {
              this.conekta.setPublicKey(Config.conektaPublicKey)
            }
            resolve(this.conekta)
          },
          false
        )
        document.body.appendChild(script)
        return null
      }
      resolve(this.conekta)
    })
  }

  getCardBrand = (cardNumber: string) => {
    return window.Conekta.card.getBrand(cardNumber)
  }
  validateCardNumber = (cardNumber: string) => {
    return window.Conekta.card.validateNumber(cardNumber)
  }
  validateCvc = (cvc: string) => {
    return window.Conekta.card.validateCVC(cvc)
  }
  validateExpirationDate = (expiryMonth: number, expiryYear: number) => {
    return window.Conekta.card.validateExpirationDate(expiryMonth, expiryYear)
  }
  tokenize = (
    cardNumber: string,
    cardHolder: string,
    expiryMonth: number,
    expiryYear: number,
    cvc: string,
    successCallback: any,
    errorCallback: any
  ) => {
    const tokenParams = {
      card: {
        cvc,
        number: cardNumber,
        name: cardHolder,
        exp_year: expiryYear,
        exp_month: expiryMonth
      }
    }
    window.Conekta.Token.create(tokenParams, successCallback, errorCallback)
  }
}

const instance = new ConektaService()

export default instance
