import { combineReducers } from 'redux'
import { userReducer, UserState } from './user'
import { TravelState, travelReducer } from './travel'
import { Travel } from 'modules/travel/models'
import { InvoiceState, invoiceReducer } from './invoice'
import { AllianceState, Alliances, allianceReducer } from './alliances'
import { StateState, States, stateReducer } from './states'
import { CityState, Cities, cityReducer } from './cities'
import { User } from 'auth/models'
import { Invoice } from 'modules/invoice/models'
import { ParcelState, parcelReducer } from './parcel'
export interface ApplicationState {
  user: UserState
  travel: TravelState
  alliances: AllianceState
  states: StateState
  cities: CityState
  invoice: InvoiceState
  parcel: ParcelState
}

export interface StateProps {
  user?: User
  travel?: Travel
  alliances: Alliances
  states: States
  cities: Cities
  invoice: Invoice
}

const reducer = combineReducers<ApplicationState>({
  user: userReducer,
  travel: travelReducer,
  alliances: allianceReducer,
  states: stateReducer,
  cities: cityReducer,
  invoice: invoiceReducer,
  parcel: parcelReducer
})

export { reducer as rootReducer }
