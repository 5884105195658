import {
  AvailableRoutesQuery,
  InitTravelBody,
  InitTravelResponse,
  PayBody,
  TravelDetailResponse,
  CalculateDiscountResponse,
  PriceListBody,
  Promo
} from './types'
import { ApiService } from 'core/services'
import { Schedule, Passenger } from 'modules/travel/models'
import { BusStatus } from 'modules/travel/models/BusStatus'
import moment from 'moment'
import { Config } from 'core'

class TravelService {
  public static instance: TravelService

  constructor() {
    if (TravelService.instance) {
      return TravelService.instance
    }
    TravelService.instance = this
  }

  availabeRoutes(query: AvailableRoutesQuery) {
    if (!query.toid) {
      return Promise.reject('[LOCAL] Origin not specified')
    }
    if (!query.tdid) {
      return Promise.reject('[LOCAL] Destination not specified')
    }
    if (!query.dt) {
      return Promise.reject('[LOCAL] Date not specified')
    }
    if (!query.stl) {
      return Promise.reject('[LOCAL] Special tickets list not specified')
    }

    return ApiService.post<Schedule[], AvailableRoutesQuery>('schedulesRoutes/availableRoutes', query)
  }

  availableSeats(id: number) {
    if (!id) {
      return Promise.reject('[LOCAL] Scheduled route not specified')
    }
    return ApiService.get<BusStatus>(`boardingPasses/seats/${id}`)
  }

  initTravel(body: InitTravelBody) {
    return ApiService.post<InitTravelResponse, InitTravelBody>('boardingPasses/register/init', body)
  }

  payTravel(body: PayBody) {
    return ApiService.post<any, PayBody>('conekta/pay', body)
  }

  cancelTravel(code: string) {
    return ApiService.delete<undefined>(`boardingPasses/register/init/cancel/${code}`)
  }

  getTravelInfo(code: string) {
    return ApiService.get<TravelDetailResponse>(`/boardingPasses/publicReservationDetail/${code}`)
  }

  getPriceList(query: PriceListBody) {
    return ApiService.post<Schedule[], PriceListBody>('boardingPasses/configPrices/list', query)
  }

  checkPromoCode(
    discount: { code: string; travelType: number; stl: number[]; isOpen: boolean },
    travel: { passengers: Passenger[]; customer_id?: number; id: number; amount: number }
  ) {
    const date = moment().format('YYYY-MM-DD')
    let rule
    let ticket_type = 'sencillo'
    if (discount.travelType === 2) rule = 'boardingpass_sencillo'
    if (discount.travelType === 3) {
      rule = !discount.isOpen ? 'boardingpass_redondo' : 'boardingpass_abierto'
      ticket_type = 'redondo'
    }

    const products = travel.passengers.reduce((acu, p) => {
      if (p.boardingpass && p.special_ticket) {
        const special_ticket_id = p.special_ticket.id
        p.boardingpass.tickets.forEach(t => {
          const value = {
            ticket_type,
            special_ticket_id,
            id: t.id,
            amount: t.amount,
            discount: t.discount,
            total_amount: t.total_amount,
            ticket_type_route: t.travelType || 'ida'
          }
          acu.push(value)
        })
      }
      return acu
    }, [] as any[])

    return ApiService.post<CalculateDiscountResponse, any>('promos/calculatePromoCode', {
      products,
      discount: {
        date,
        rule,
        customer_id: travel.customer_id,
        discount_code: discount.code,
        service: 'boardingpass',
        special_ticket_id: discount.stl,
        num_products: products.length,
        purchase_origin: Config.origin
      },
      service: {
        id: travel.id,
        amount: travel.amount
      }
    })
  }

  promosList(discount: { stl: { id: number; number: number }[]; travelType: number; isOpen: boolean }) {
    let rule
    if (discount.travelType === 2) rule = 'boardingpass_sencillo'
    if (discount.travelType === 3) {
      rule = !discount.isOpen ? 'boardingpass_redondo' : 'boardingpass_abierto'
    }
    const today = moment()
    const body = {
      rule,
      purchase_origin: Config.origin,
      date: today.format('YYYY-MM-DD'),
      apply_to_special_tickets: discount.stl.map(st => st.id),
      service: 'boardingpass',
      has_specific_customer: false,
      num_products: discount.stl.reduce((acu, next) => acu + next.number, 0)
    }
    return ApiService.post<Promo[], any>('promos/list', body)
  }
}

const instance = new TravelService()

export default instance
