import { Reducer } from 'redux'
import { UserState, UserActions } from './types'

// Type-safe initialState!
const initialState: UserState = null

const reducer: Reducer<UserState> = (state = initialState, action) => {
  switch (action.type) {
    case UserActions.SET_USER_DATA: {
      return {
        ...state,
        ...action.payload
      }
    }
    case UserActions.REMOVE_USER_DATA: {
      return null
    }
    default: {
      return state
    }
  }
}

export { reducer as userReducer }
