import { Reducer } from 'redux'
import { TravelState, TravelActions } from './types'
import { Travel } from 'modules/travel/models'

// Type-safe initialState!
const initialState: TravelState = {
  currentTravel: new Travel(),
  special_tickets: [],
  terminals: [],
  corp: [],
  availableRoutes: []
}

const reducer: Reducer<TravelState> = (state = initialState, action) => {
  switch (action.type) {
    case TravelActions.SET_TRAVEL_DATA: {
      return {
        ...state,
        currentTravel: { ...state.currentTravel, ...action.payload }
      }
    }
    case TravelActions.REMOVE_TRAVEL_DATA: {
      return {
        ...state,
        currentTravel: { ...initialState.currentTravel }
      }
    }
    case TravelActions.GET_TERMINALS: {
      return {
        ...state,
        terminals: action.payload
      }
    }
    case TravelActions.GET_CORP: {
      return {
        ...state,
        corp: action.payload
      }
    }
    case TravelActions.GET_SPECIAL_TICKETS: {
      return {
        ...state,
        special_tickets: action.payload
      }
    }
    case TravelActions.GET_AVAILABLE_ROUTES: {
      return {
        ...state,
        availableRoutes: action.payload
      }
    }
    case TravelActions.REMOVE_TRAVEL_DISCOUNT: {
      const travel = new Travel({ ...state.currentTravel })
      delete travel.discountInfo
      return {
        ...state,
        currentTravel: { ...travel }
      }
    }
    default: {
      return state
    }
  }
}

export { reducer as travelReducer }
