import { Action } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { PackageType } from 'modules/parcels/services/TrackingService/types'

export enum ParcelActions {
  GET_PACKAGE_TYPES = '@@parcel/GET_PACKAGE_TYPES'
}

export interface GetPackageTypes extends Action {
  type: ParcelActions.GET_PACKAGE_TYPES
  payload: PackageType[]
}

export interface ParcelState {
  types: Readonly<PackageType[]>
}

type ParcelExtraArg = undefined

export type ParcelAction = GetPackageTypes

export type ParcelsActionThunk<R> = ThunkAction<R, ParcelState, ParcelExtraArg, ParcelAction>

export type ParcelThunkDispatch = ThunkDispatch<ParcelState, ParcelExtraArg, Action>

export type AsyncParcelAction<T> = ParcelsActionThunk<Promise<T>>
