import { Action } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

export interface Cities {
  id?: number
  name: string
}

export enum CityActions {
  GET_CITIES = '@@cities/GET_CITIES'
}

export interface GetCities extends Action {
  type: CityActions.GET_CITIES
  payload: Cities[]
}

export interface CityState {
  cities: Readonly<Cities[]>
}

type CityExtraArg = undefined

export type CityAction = GetCities

export type CitiesActionThunk<R> = ThunkAction<R, CityState, CityExtraArg, CityAction>

export type CityThunkDispatch = ThunkDispatch<CityState, CityExtraArg, Action>

export type AsyncCityAction<T> = CitiesActionThunk<Promise<T>>
