import { States, AsyncStateAction, StateActions } from './types'
import { ApiService } from 'core/services'

export const getStates = (): AsyncStateAction<States[]> => {
  return dispatch => {
    // tslint:disable-next-line: no-shadowed-variable
    return ApiService.get<States[]>('alliances/states').then(States => {
      dispatch({
        type: StateActions.GET_STATES,
        payload: States
      })
      return Promise.resolve(States)
    })
  }
}
