import CommonFields from 'shared/models/CommonFields'

export default class Customer implements CommonFields {
  public id: number = 0
  public birthday: string = ''
  public conekta_id: number = 0
  public credit_limit: number = 0
  public credit_time_limit: number = 0
  public email: string = ''
  public first_name: string = ''
  public gender: 'm' | 'f' | 'n' = 'm'
  public has_credit: boolean = false
  public image: string = ''
  public is_business: boolean = false
  public is_verified: boolean = false
  public last_name: string = ''
  public phone: string = ''
  public registered_at: string = ''
  public times_blocked: number = 0
  public times_complain: number = 0
  public token: string = ''
  public user_id: number = 0
  public status: number = 0
  public created_by: number = 0
  public updated_by: number = 0
  public created_at: string = ''
  public updated_at: string = ''
  public parcel_type: string = ''

  constructor(customer?: Partial<Customer>) {
    Object.assign(this, customer)
  }
}
