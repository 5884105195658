import { Moment } from 'moment'
import Schedule from './Schedule'

export default class ScheduleInfo {
  public date?: Moment
  public bus?: Bus
  public schedule?: Schedule

  constructor(scheduleInfo?: Partial<ScheduleInfo>) {
    Object.assign(this, scheduleInfo)
  }
}

type BusRow = BusSeat[]

export type Bus = BusRow[]

export interface BusSeat {
  seat?: string
  position?: string
  no_seat?: boolean
  type?: SeatType
  selected?: boolean
}

export enum SeatType {
  busy = 'not_available_seat',
  women = 'available_women_seat',
  handicapped = 'available_handicapped_seat',
  available = 'available_seat'
}
