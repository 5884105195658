import { Reducer } from 'redux'
import { AllianceState, AllianceActions } from './types'

// Type-safe initialState!
const initialState: AllianceState = {
  alliances: {
    count: 0,
    items: 0,
    results: []
  },
  allianceServices: [],
  allianceCategory: []
}

const reducer: Reducer<AllianceState> = (state = initialState, action) => {
  switch (action.type) {
    case AllianceActions.GET_ALLIANCES: {
      return {
        ...state,
        alliances: action.payload
      }
    }
    case AllianceActions.GET_SERVICES: {
      return {
        ...state,
        allianceServices: action.payload
      }
    }
    case AllianceActions.GET_CATEGORIES: {
      return {
        ...state,
        allianceCategory: action.payload
      }
    }
    default: {
      return state
    }
  }
}

export { reducer as allianceReducer }
