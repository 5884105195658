import { UserAction, UserActions } from './types'
import { User } from 'auth/models'

export const setUserData = (user: Partial<User>): UserAction => {
  return {
    type: UserActions.SET_USER_DATA,
    payload: user
  }
}

export const removeUserData = (): UserAction => {
  return {
    type: UserActions.REMOVE_USER_DATA
  }
}
