import { Reducer } from 'redux'
import { ParcelState, ParcelActions } from './types'

// Type-safe initialState!
const initialState: ParcelState = {
  types: []
}

const reducer: Reducer<ParcelState> = (state = initialState, action) => {
  switch (action.type) {
    case ParcelActions.GET_PACKAGE_TYPES: {
      return {
        ...state,
        types: action.payload
      }
    }
    default: {
      return state
    }
  }
}

export { reducer as parcelReducer }
