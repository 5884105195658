export enum ApiErrors {
  HANDLED_ERROR = 'HANDLED_ERROR',
  CANCELED_REQUEST = 'CANCELED_REQUEST'
}

export interface ApiResponse<T = any> {
  status: ApiResponseStatus
  message?: string
  devMessage?: string
  data?: T
}

export enum ApiResponseStatus {
  OK = 'OK',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  INVALID_TOKEN = 'INVALID_TOKEN'
}
