import { Customer } from 'modules/travel/models'

export type InvoiceStatus = 'progress' | 'done' | 'error' | 'expired' | 'pending' | 'reload' | 'not_required'

export type InvoiceType = 'boarding_pass' | 'parcel' | 'rental' | 'freight' | 'guia_pp'

export default class Invoice {
  static statusTexts: { [k in InvoiceStatus]: string } = {
    progress: 'En progreso',
    done: 'Realizada',
    error: 'Hay un problema',
    expired: 'No elegible',
    pending: 'Pendiente',
    reload: 'Servicio no disponible',
    not_required: 'No requerido'
  }

  public invoice_id?: number
  public media_document_pdf_name?: string
  public media_document_xml_name?: string
  public invoice_status: InvoiceStatus = 'pending'
  public customer: Customer = new Customer()
  public code: string = ''
  public service: string = ''
  public amount: number = 0
  public iva: number = 0
  public discount: number = 0
  public total_amount: number = 0
  public email: string = ''
  public service_type: InvoiceType = 'boarding_pass'
  public errorMessage?: string = ''

  constructor(invoice?: Partial<Invoice>) {
    Object.assign(this, invoice)
  }
}
