import { AsyncParcelAction, ParcelActions } from './types'
import { PackageType } from 'modules/parcels/services/TrackingService/types'
import { ParcelService } from 'modules/parcels/services'

export const fetchPackageTypes = (): AsyncParcelAction<PackageType[]> => {
  return dispatch => {
    return ParcelService.getPackageTypes().then(types => {
      dispatch({
        type: ParcelActions.GET_PACKAGE_TYPES,
        payload: types
      })
      return Promise.resolve(types)
    })
  }
}
