import { Reducer } from 'redux'
import { StateState, StateActions } from './types'

// Type-safe initialState!
const initialState: StateState = {
  states: []
}

const reducer: Reducer<StateState> = (state = initialState, action) => {
  switch (action.type) {
    case StateActions.GET_STATES: {
      return {
        ...state,
        states: action.payload
      }
    }
    default: {
      return state
    }
  }
}

export { reducer as stateReducer }
