import { Moment } from 'moment'
import { BusSeat } from './ScheduleInfo'
import SpecialTicket from './SpecialTicket'
import { ResponsePassenger } from '../services/TravelService/types'

export default class Passenger {
  id: number = 0
  first_name: string = ''
  last_name: string = ''
  gender: 'm' | 'f' | 'n' = 'm'
  birthday: Moment | null = null
  special_ticket: SpecialTicket | null = null
  min_date: Moment | null = null
  max_date: Moment | null = null
  is_kid: boolean = false
  need_preferential: boolean = false
  principal_passenger: boolean = false
  is_customer: boolean = false
  departure_seat?: BusSeat
  return_seat?: BusSeat
  hash: string = ''
  public boardingpass?: ResponsePassenger

  constructor(passenger?: Partial<Passenger>) {
    Object.assign(this, passenger)
  }

  public get full_name(): string {
    return `${this.first_name} ${this.last_name}`
  }
}
