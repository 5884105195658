import { AsyncAllianceAction, AllianceActions, AllianceCategory, AlliancesResponse } from './types'
import { GenericService, ApiService } from 'core/services'

export const getAlliances = (
  state_id?: number,
  city_id?: number,
  page?: number,
  typeService?: number
): AsyncAllianceAction<AlliancesResponse> => {
  let CITYID: string
  let TYPESERVICE: string
  let STATEID: string
  if (!page) {
    // tslint:disable-next-line: no-parameter-reassignment
    page = 1
  }
  if (!city_id) {
    CITYID = ''
  } else {
    CITYID = `city_id=${city_id}`
  }
  if (!typeService) {
    TYPESERVICE = ''
  } else {
    TYPESERVICE = `alliance_category_id=${typeService}`
  }
  if (!state_id) {
    STATEID = ''
  } else {
    STATEID = `state_id=${state_id}`
  }
  return dispatch => {
    return ApiService.get<AlliancesResponse>(`alliances/web?limit=4&${STATEID}&${CITYID}&page=${page}&${TYPESERVICE}`).then(alliances => {
      dispatch({
        type: AllianceActions.GET_ALLIANCES,
        payload: alliances
      })
      return Promise.resolve(alliances)
    })
  }
}

export const getCategories = (): AsyncAllianceAction<AllianceCategory[]> => {
  return dispatch => {
    return GenericService.list<AllianceCategory[]>('allianceCategories').then(allianceCategories => {
      dispatch({
        type: AllianceActions.GET_CATEGORIES,
        payload: allianceCategories
      })
      return Promise.resolve(allianceCategories)
    })
  }
}
