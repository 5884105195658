import moment, { Moment } from 'moment'

export const parseDate0 = (date: string, format: string) => {
  return moment(date)
    .utcOffset(0)
    .format(format)
}

export const humanDiff = (d1: string, d2: string) => {
  const date1 = moment(d1)
  const date2 = moment(d2)
  const diff = moment.duration(date2.diff(date1))
  const hoursDiff = Math.round(diff.asHours())
  return hoursDiff === 1 || hoursDiff < 1 ? 'Una hora' : `${hoursDiff} horas`
}

export const validMomentValue = (value: Moment, min: Moment, max: Moment) => {
  let val = value

  if (min && max) {
    if (max.isBefore(min)) {
      const err = { message: 'min value is greater than max value' }
      throw err
    }

    val = moment.max(val, min)
    val = moment.min(val, max)
    return val
  }

  if (max) {
    return moment.min(val, max)
  }

  if (min) {
    return moment.max(val, min)
  }

  return val
}
