import { Action } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

export interface States {
  id: number
  name: string
}

export enum StateActions {
  GET_STATES = '@@states/GET_STATES'
}

export interface GetStates extends Action {
  type: StateActions.GET_STATES
  payload: States[]
}

export interface StateState {
  states: Readonly<States[]>
}

type StateExtraArg = undefined

export type StateAction = GetStates

export type StatesActionThunk<R> = ThunkAction<R, StateState, StateExtraArg, StateAction>

export type StateThunkDispatch = ThunkDispatch<StateState, StateExtraArg, Action>

export type AsyncStateAction<T> = StatesActionThunk<Promise<T>>
