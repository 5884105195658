import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { Action } from 'redux'
import { ApplicationState } from 'store'
import { Travel, Terminal, SpecialTicket, Schedule } from 'modules/travel/models'

// Redux types

export enum TravelActions {
  SET_TRAVEL_DATA = '@@travel/SET_TRAVEL_DATA',
  REMOVE_TRAVEL_DATA = '@@travel/REMOVE_TRAVEL_DATA',
  GET_TERMINALS = '@@travel/GET_TERMINALS',
  GET_CORP = '@@travel/GET_CORP',
  GET_SPECIAL_TICKETS = '@@travel/GET_SPECIAL_TICKETS',
  GET_AVAILABLE_ROUTES = '@@travel/GET_AVAILABLE_ROUTES',
  REMOVE_TRAVEL_DISCOUNT = '@@travel/REMOVE_TRAVEL_DISCOUNT'
}

export interface GetCorp extends Action {
  type: TravelActions.GET_CORP
  payload: Terminal[]
}
export interface SetTravelData extends Action {
  type: TravelActions.SET_TRAVEL_DATA
  payload: Travel
}

export interface RemoveTravelData extends Action {
  type: TravelActions.REMOVE_TRAVEL_DATA
}

export interface RemoveTravelDiscount extends Action {
  type: TravelActions.REMOVE_TRAVEL_DISCOUNT
}

export interface GetTerminals extends Action {
  type: TravelActions.GET_TERMINALS
  payload: Terminal[]
}

export interface GetSpecialTickets extends Action {
  type: TravelActions.GET_SPECIAL_TICKETS
  payload: SpecialTicket[]
}

export interface GetAvailableRoutes extends Action {
  type: TravelActions.GET_AVAILABLE_ROUTES
  payload: Schedule[]
}

export interface TravelState {
  currentTravel: Readonly<Travel>
  terminals: Readonly<Terminal[]>
  corp: Readonly<Terminal[]>
  special_tickets: Readonly<SpecialTicket[]>
  availableRoutes: Readonly<Schedule[]>
}

type TravelExtraArg = undefined

export type TravelAction =
  | SetTravelData
  | RemoveTravelData
  | GetTerminals
  | GetSpecialTickets
  | GetAvailableRoutes
  | RemoveTravelDiscount
  | GetCorp

export type TravelActionThunk<R> = ThunkAction<R, ApplicationState, TravelExtraArg, TravelAction>

export type TravelThunkDispatch = ThunkDispatch<ApplicationState, TravelExtraArg, Action>

export type AsyncTravelAction<T> = TravelActionThunk<Promise<T>>
