import { Cities, AsyncCityAction, CityActions } from './types'
import { ApiService } from 'core/services'

export const getCities = (state_id: number): AsyncCityAction<Cities[]> => {
  return dispatch => {
    // tslint:disable-next-line: no-shadowed-variable
    return ApiService.get<Cities[]>(`alliances/cities?state_id=${state_id}`).then(Cities => {
      dispatch({
        type: CityActions.GET_CITIES,
        payload: Cities
      })
      return Promise.resolve(Cities)
    })
  }
}
