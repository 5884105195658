import { createSelector } from 'reselect'

import { ApplicationState } from 'store'
import { Travel, Schedule, SpecialTicket, Terminal, ScheduleInfo } from 'modules/travel/models'

export const getTravel = (state: ApplicationState): Travel => state.travel.currentTravel

export const getAvailableRoutes = (state: ApplicationState): Schedule[] => [...state.travel.availableRoutes]

export const getSpecialTickets = (state: ApplicationState): SpecialTicket[] => [...state.travel.special_tickets]

export const getTerminals = (state: ApplicationState): Terminal[] => [...state.travel.terminals]

export const getCorp = (state: ApplicationState): Terminal[] => [...state.travel.corp]
export const getDeparture = createSelector(
  [getTravel],
  (travel: Travel): ScheduleInfo => {
    return travel.departure || {}
  }
)

export const getReturn = createSelector(
  [getTravel],
  (travel: Travel): ScheduleInfo => {
    return travel.return || {}
  }
)
